<template>
  <bs-popup id="master-vendor-popup" @on-popup-closed="onPopupClosed" :auto-show="true" ref="form">
    <template v-slot:title>
      Master Time Range
    </template>
    <template v-slot:content>
      <bs-error-message :errors="errorMessages" />

      <div class="mb-3">
        <label for="code" class="form-label">Nama</label>
        <bs-textbox v-model="model.nama" />
        <bs-input-error :errors="errorMessages" keyError="Code" />
      </div>
    </template>
    <template v-slot:footer>
      <button class="btn btn-sm btn-primary" @click="saveData"><i class="bi bi-check-circle"></i> Save</button>
      <button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
    </template>
  </bs-popup>
</template>

<script>
import MasterTimeRangeService from "@/services/master-time-range-service.js";

export default {
  components: {

  },
  data: function () {
    return {
      id: 0,
      model: MasterTimeRangeService.default(),
      errorMessages: null
    }
  },
  mounted() {
    let id = this.$route.params.id;
    if (id !== undefined && id !== null && id !== 0) {
      this.id = id;
      this.loadData();
    }
  },
  methods: {
    async loadData() {
      if (this.id === undefined || this.id === null || this.id === 0) {
        return;
      }

      const result = await MasterTimeRangeService.getObject(this.id);
      if (result.status) {
        this.errorMessages = result.errorMessages;
      }
      else {
        this.errorMessages = null;
      }

      this.model = result.data;
    },
    async onPopupClosed() {
      this.$router.push('/master-time-range');
    },
    async close() {
      this.$refs.form.closePopup();
    },
    async saveData() {
      try {
        this.$store.commit('progressCounterQueueIncrement');

        let status = false;
        if (this.id !== undefined && this.id !== null && this.id !== 0) {
          if (this.model.interval){
            this.model.interval = parseInt(this.model.interval);
          }
          const result = await MasterTimeRangeService.update(this.model, this.id);
          status = result.status;
          if (status) {
            this.errorMessages = null;
          }
          else {
            this.errorMessages = result.errorMessages;
          }
        }
        else {
          if (this.model.interval){
            this.model.interval = parseInt(this.model.interval);
          }
          const result = await MasterTimeRangeService.create(this.model);
          status = result.status;
          console.log('save status', status);
          if (status) {
            this.errorMessages = null;
            this.id = result.data.id;
          }
          else {
            this.errorMessages = result.errorMessages;
          }
        }

        if (!status) return;

        this.$refs.form.closePopup();
        this.$router.push(`/master-time-range/detail/${this.id}`);
      }
      finally {
        this.$store.commit('progressCounterQueueDecrement');
      }
    }
  }
}
</script>